import React, { useState, useEffect, useRef } from "react";

// import { Line, PolarArea } from "react-chartjs-2";

// import { Bar } from "react-chartjs-2";

import { Temperature } from "react-environment-chart";
import { Humidity } from "react-environment-chart";
import { PM } from "react-environment-chart";
import { Electricity } from "react-environment-chart";
// import { Intensity } from "react-environment-chart";
import Typography from "@mui/material/Typography";
// import { GiComputerFan } from "react-icons/gi";

// import { TbAirConditioning, TbBold } from "react-icons/tb";
// import { MdOutlineAir } from "react-icons/md";

// import { WiHumidity } from "react-icons/wi";

// import { useProSidebar } from "react-pro-sidebar";
// import Switch from "react-switch";
import { useAuthContext } from "../hooks/useAuthContext";
// import { useSensorDatasContext } from "../hooks/useSensorDatasContext";
// import { green } from "@mui/material/colors";

import moment from "moment";

// import "./ShmSensorBox.css";

// import DynamicChart from "./DynamicChart";/

// // var labels123 = ["January", "February", "March", "April", "May", "June"];
// var temperatureChartData = {
//   // labels: ["January", "February", "March", "April", "May", "June"],
//   labels: [],
//   datasets: [
//     // {
//     //   label: "Sales",
//     //   data: [21, 35, 75, 51, 41, 47],
//     //   fill: false, // for Line chart
//     //   backgroundColor: "#FB8833",
//     //   borderColor: "#FB8833", // for Line chart
//     // },
//     {
//       label: "Temperature",
//       data: [],
//       fill: false, // for Line chart
//       backgroundColor: "#17A8F5",
//       borderColor: "#17A8F5", // for Line chart
//     },
//   ],
// };

// var vibrationChartData = {
//   // labels: ["January", "February", "March", "April", "May", "June"],
//   labels: [],
//   datasets: [
//     // {
//     //   label: "Sales",
//     //   data: [21, 35, 75, 51, 41, 47],
//     //   fill: false, // for Line chart
//     //   backgroundColor: "#FB8833",
//     //   borderColor: "#FB8833", // for Line chart
//     // },
//     {
//       label: "Vibration",
//       data: [],
//       fill: false, // for Line chart
//       backgroundColor: "#17A8F5",
//       borderColor: "#17A8F5", // for Line chart
//     },
//   ],
// };

// var tiltMeterChartData = {
//   // labels: ["January", "February", "March", "April", "May", "June"],
//   labels: [],
//   datasets: [
//     // {
//     //   label: "Sales",
//     //   data: [21, 35, 75, 51, 41, 47],
//     //   fill: false, // for Line chart
//     //   backgroundColor: "#FB8833",
//     //   borderColor: "#FB8833", // for Line chart
//     // },
//     {
//       label: "TiltMeter",
//       data: [],
//       fill: false, // for Line chart
//       backgroundColor: "#17A8F5",
//       borderColor: "#17A8F5", // for Line chart
//     },
//   ],
// };

// var accelerometerChartData = {
//   // labels: ["January", "February", "March", "April", "May", "June"],
//   labels: [],
//   datasets: [
//     // {
//     //   label: "Sales",
//     //   data: [21, 35, 75, 51, 41, 47],
//     //   fill: false, // for Line chart
//     //   backgroundColor: "#FB8833",
//     //   borderColor: "#FB8833", // for Line chart
//     // },
//     {
//       label: "Accelerometer",
//       data: [],
//       fill: false, // for Line chart
//       backgroundColor: "#17A8F5",
//       borderColor: "#17A8F5", // for Line chart
//     },
//   ],
// };

import ProgressBar from "./ProgressBar.js";

const ShmSensorBox = (props) => {
  // let [sensordata, setsensordata] = useState(
  //   '{"deviceID":"869523050076489","deviceType":"shm","csq":"21","batVoltage":"11.7","fiberOptic":"20","accelerometer":"70","vibration":"0.00","loadCell":"0.00","strainGauge":"0","inclinoMeter":"0","tiltMeter":"0","acousticEmission":"124","temperature":"22","warning":"0","error":"0","timestamp":"1684133370"}'
  // );

  // var [labelsstate, setlabelsstate] = React.useState(temperatureChartData);

  const { user } = useAuthContext();

  // const deviceID = props.deviceID;

  // const [items, setItems] = useState();

  // const [items2, setItems2] = useState();
  // const [items3, setItems3] = useState();
  // const [items4, setItems4] = useState();

  // useEffect(() => {
  // const apis = "/api/deviceAssigned/" + useremail;
  // // console.log("apis:")
  // // console.log(apis)
  // const response = await fetch(apis, {
  //   headers: { Authorization: `Bearer ${user.token}` },
  // });
  // const apis = "/api/sensorDatas/shm/date/" + deviceID + "/" + "1";
  // console.log("apis:", apis);
  // fetch(apis, {
  //   headers: { Authorization: `Bearer ${user.token}` },
  // })
  //   .then((res) => res.json())
  //   .then((data) => {
  //     console.log("data:", data);
  //     const length = data.length;
  //     console.log("length:");
  //     console.log(length);
  //     // console.log("json11111:");
  //     // console.log(json1[0]._id);
  //     // labels = [];
  //     // values = [];
  //     for (var i = 0; i < length; i++) {
  //       const date = moment.unix(data[i].timestamp).format("DD/MM/YYYY");
  //       const time = moment.unix(data[i].timestamp).format("hh:mm:ss");
  //       temperatureChartData.labels[i] = time + " " + date;
  //       // temperatureChartData.labels[i] = data[i].createdAt;
  //       temperatureChartData.datasets[0].data[i] = data[i].temperature;
  //       vibrationChartData.labels[i] = time + " " + date;
  //       vibrationChartData.datasets[0].data[i] = data[i].vibration;
  //       tiltMeterChartData.labels[i] = time + " " + date;
  //       tiltMeterChartData.datasets[0].data[i] = data[i].tiltMeter;
  //       accelerometerChartData.labels[i] = time + " " + date;
  //       accelerometerChartData.datasets[0].data[i] = data[i].accelerometer;
  //     }
  //     // console.log("temperatureChartData.labels", temperatureChartData.labels);
  //     // console.log("temperatureChartData.datasets", temperatureChartData.datasets);
  //     setItems2(vibrationChartData);
  //     setItems3(tiltMeterChartData);
  //     setItems4(accelerometerChartData);
  //     setItems(temperatureChartData);
  //   });
  // }, []);

  // const fetchSensorDataDetail1 = async (deviceID) => {
  //   const apis = "/api/sensorDatas/biofly/date/" + deviceID + "/" + "1";
  //   const response = await fetch(apis, {
  //     headers: { Authorization: `Bearer ${user.token}` },
  //   });
  //   const json1 = await response.json();
  //   if (response.ok) {
  //     // console.log("json1:");
  //     // console.log(json1);

  //     const length = json1.length;
  //     // console.log("length:");
  //     // console.log(length);

  //     // console.log("json11111:");
  //     // console.log(json1[0]._id);

  //     // labels = [];
  //     // values = [];
  //     for (var i = 0; i < length; i++) {
  //       const date = moment.unix(json1[i].timestamp).format("DD/MM/YYYY");
  //       const time = moment.unix(json1[i].timestamp).format("hh:mm:ss");

  //       // data11.labels[i] = time + date;
  //       // data11.datasets[0].data[i] = json1[i].temperature;
  //     }

  //     // console.log("data.labels", data11.labels);
  //     // console.log("data.datasets", data11.datasets);
  //     // setlabelsstate(data11);
  //   }
  //   return json1;
  // };

  // const [chartData, setChartData] = useState({});
  // const [employeeSalary, setEmployeeSalary] = useState([]);
  // const [employeeAge, setEmployeeAge] = useState([]);

  // const Chart = (deviceID) => {
  //   const apis = "/api/sensorDatas/biofly/date/" + deviceID + "/" + "1";

  //   let empSal = [];
  //   let empAge = [];

  //   fetch(apis, {
  //     headers: { Authorization: `Bearer ${user.token}` },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log("data: ", data);
  //       // setItems(data)

  //       const length = data.length;
  //       // console.log("length:");
  //       // console.log(length);

  //       // console.log("json11111:");
  //       // console.log(json1[0]._id);

  //       // labels = [];
  //       // values = [];
  //       for (var i = 0; i < length; i++) {
  //         const date = moment.unix(data[i].timestamp).format("DD/MM/YYYY");
  //         const time = moment.unix(data[i].timestamp).format("hh:mm:ss");

  //         data.labels[i] = time + date;
  //         data.datasets[0].data[i] = data[i].temperature;

  //         empSal.push(data.datasets[0].data[i]);
  //         empAge.push(data.labels[i]);
  //       }

  //       console.log("data.labels", data.labels);
  //       console.log("data.datasets", data.datasets);

  //       setlabelsstate(data);
  //       // setChartData({
  //       //   labels: empAge,
  //       //   datasets: [
  //       //     {
  //       //       label: "level of thicceness",
  //       //       data: empSal,
  //       //       backgroundColor: [
  //       //         "rgba(255, 99, 132, 0.2)",
  //       //         "rgba(54, 162, 235, 0.2)",
  //       //         "rgba(255, 206, 86, 0.2)",
  //       //         "rgba(75, 192, 192, 0.2)",
  //       //         "rgba(153, 102, 255, 0.2)",
  //       //         "rgba(255, 159, 64, 0.2)",
  //       //         "rgba(255, 99, 132, 0.2)",
  //       //         "rgba(54, 162, 235, 0.2)",
  //       //         "rgba(255, 206, 86, 0.2)",
  //       //         "rgba(75, 192, 192, 0.2)",
  //       //         "rgba(153, 102, 255, 0.2)",
  //       //         "rgba(255, 159, 64, 0.2)",
  //       //         "rgba(255, 99, 132, 0.2)",
  //       //         "rgba(54, 162, 235, 0.2)",
  //       //         "rgba(255, 206, 86, 0.2)",
  //       //         "rgba(75, 192, 192, 0.2)",
  //       //         "rgba(153, 102, 255, 0.2)",
  //       //         "rgba(255, 159, 64, 0.2)",
  //       //         "rgba(255, 99, 132, 0.2)",
  //       //         "rgba(54, 162, 235, 0.2)",
  //       //         "rgba(255, 206, 86, 0.2)",
  //       //         "rgba(75, 192, 192, 0.2)",
  //       //         "rgba(153, 102, 255, 0.2)",
  //       //         "rgba(255, 159, 64, 0.2)",
  //       //       ],
  //       //       borderColor: [
  //       //         "rgba(255, 99, 132, 1)",
  //       //         "rgba(54, 162, 235, 1)",
  //       //         "rgba(255, 206, 86, 1)",
  //       //         "rgba(75, 192, 192, 1)",
  //       //         "rgba(153, 102, 255, 1)",
  //       //         "rgba(255, 159, 64, 1)",
  //       //         "rgba(255, 99, 132, 1)",
  //       //         "rgba(54, 162, 235, 1)",
  //       //         "rgba(255, 206, 86, 1)",
  //       //         "rgba(75, 192, 192, 1)",
  //       //         "rgba(153, 102, 255, 1)",
  //       //         "rgba(255, 159, 64, 1)",
  //       //         "rgba(255, 99, 132, 1)",
  //       //         "rgba(54, 162, 235, 1)",
  //       //         "rgba(255, 206, 86, 1)",
  //       //         "rgba(75, 192, 192, 1)",
  //       //         "rgba(153, 102, 255, 1)",
  //       //         "rgba(255, 159, 64, 1)",
  //       //         "rgba(255, 99, 132, 1)",
  //       //         "rgba(54, 162, 235, 1)",
  //       //         "rgba(255, 206, 86, 1)",
  //       //         "rgba(75, 192, 192, 1)",
  //       //         "rgba(153, 102, 255, 1)",
  //       //         "rgba(255, 159, 64, 1)",
  //       //       ],
  //       //       borderWidth: 1,
  //       //     },
  //       //   ],
  //       // });
  //     });

  //   // .then((res) => {
  //   //   console.log(res);
  //   //   // for (const dataObj of res.data.data) {
  //   //   // empSal.push(parseInt(dataObj.employee_salary));
  //   //   // empAge.push(parseInt(dataObj.employee_age));
  //   //   // }

  //   //   // const length1 = res.data.length;
  //   //   // console.log("length1:");
  //   //   // console.log(length1);

  //   //   // console.log("json11111:");
  //   //   // console.log(json1[0]._id);

  //   //   // labels = [];
  //   //   // values = [];
  //   //   // for (var i = 0; i < length; i++) {
  //   //   //   const date = moment.unix(json1[i].timestamp).format("DD/MM/YYYY");
  //   //   //   const time = moment.unix(json1[i].timestamp).format("hh:mm:ss");

  //   //   //   data.labels[i] = time + date;
  //   //   //   data.datasets[0].data[i] = json1[i].temperature;
  //   //   // }

  //   //   setChartData({
  //   //     labels: empAge,
  //   //     datasets: [
  //   //       {
  //   //         label: "level of thicceness",
  //   //         data: empSal,
  //   //         backgroundColor: [
  //   //           "rgba(255, 99, 132, 0.2)",
  //   //           "rgba(54, 162, 235, 0.2)",
  //   //           "rgba(255, 206, 86, 0.2)",
  //   //           "rgba(75, 192, 192, 0.2)",
  //   //           "rgba(153, 102, 255, 0.2)",
  //   //           "rgba(255, 159, 64, 0.2)",
  //   //           "rgba(255, 99, 132, 0.2)",
  //   //           "rgba(54, 162, 235, 0.2)",
  //   //           "rgba(255, 206, 86, 0.2)",
  //   //           "rgba(75, 192, 192, 0.2)",
  //   //           "rgba(153, 102, 255, 0.2)",
  //   //           "rgba(255, 159, 64, 0.2)",
  //   //           "rgba(255, 99, 132, 0.2)",
  //   //           "rgba(54, 162, 235, 0.2)",
  //   //           "rgba(255, 206, 86, 0.2)",
  //   //           "rgba(75, 192, 192, 0.2)",
  //   //           "rgba(153, 102, 255, 0.2)",
  //   //           "rgba(255, 159, 64, 0.2)",
  //   //           "rgba(255, 99, 132, 0.2)",
  //   //           "rgba(54, 162, 235, 0.2)",
  //   //           "rgba(255, 206, 86, 0.2)",
  //   //           "rgba(75, 192, 192, 0.2)",
  //   //           "rgba(153, 102, 255, 0.2)",
  //   //           "rgba(255, 159, 64, 0.2)",
  //   //         ],
  //   //         borderColor: [
  //   //           "rgba(255, 99, 132, 1)",
  //   //           "rgba(54, 162, 235, 1)",
  //   //           "rgba(255, 206, 86, 1)",
  //   //           "rgba(75, 192, 192, 1)",
  //   //           "rgba(153, 102, 255, 1)",
  //   //           "rgba(255, 159, 64, 1)",
  //   //           "rgba(255, 99, 132, 1)",
  //   //           "rgba(54, 162, 235, 1)",
  //   //           "rgba(255, 206, 86, 1)",
  //   //           "rgba(75, 192, 192, 1)",
  //   //           "rgba(153, 102, 255, 1)",
  //   //           "rgba(255, 159, 64, 1)",
  //   //           "rgba(255, 99, 132, 1)",
  //   //           "rgba(54, 162, 235, 1)",
  //   //           "rgba(255, 206, 86, 1)",
  //   //           "rgba(75, 192, 192, 1)",
  //   //           "rgba(153, 102, 255, 1)",
  //   //           "rgba(255, 159, 64, 1)",
  //   //           "rgba(255, 99, 132, 1)",
  //   //           "rgba(54, 162, 235, 1)",
  //   //           "rgba(255, 206, 86, 1)",
  //   //           "rgba(75, 192, 192, 1)",
  //   //           "rgba(153, 102, 255, 1)",
  //   //           "rgba(255, 159, 64, 1)",
  //   //         ],
  //   //         borderWidth: 1,
  //   //       },
  //   //     ],
  //   //   });
  //   // })
  //   // .catch((err) => {
  //   //   console.log(err);
  //   // });
  // };

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log("ShmSensorBox Only once execution here only...");

    const fetchSensorDataDetail = async (deviceID) => {
      const apis = "/api/sensorDatas/shm/" + deviceID + "/" + "1";
      const response = await fetch(apis, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      const json = await response.json();
      if (response.ok) {
        const json1 = json[0];
        // console.log("json1:", json1);

        setsensordata(json1);
      }
      return json;
    };

    // fetchSensorDataDetail(deviceID);
    // fetchSensorDataDetail1(deviceID);

    fetchSensorDataDetail(props.deviceID);
  }, [user.token, props.deviceID]);

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     // const res = await fetch(`https://jsonplaceholder.typicode.com/posts`);
  //     // const json = fetchSensorDataDetail(deviceID);
  //     // fetchSensorDataDetail(deviceID);
  //     // fetchSensorDataDetail1(deviceID);
  //   }, 3000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  let [sensordata, setsensordata] = useState(
    '{"deviceID": "VBD10001","deviceType": "bioflyCM","temperature": "31","humidity": "61","lux": "1800","co2": "140","timestamp": "1687941254"}'
  );

  const date = moment.unix(sensordata.timestamp).format("DD/MM/YYYY");
  // console.log(date);

  const time = moment.unix(sensordata.timestamp).format("hh:mm:ss");
  // console.log(time);

  return (
    <section className="border text-center mb-4">
      <div>
        <p>
          Device ID: <b>{sensordata.deviceID}</b>
        </p>

        <p>
          Last updated on: Time: <b>{time}</b> Date: <b>{date}</b>
        </p>

        {/* <DynamicChart data={labelsstate} /> */}

        {/* <div>{items && <DynamicChart items={items} />}</div>

      <div>{items2 && <DynamicChart items={items2} />}</div>

      <div>{items3 && <DynamicChart items={items3} />}</div> */}

        <div className="child">
          {/* <div style={appContainer}> */}
          <div className="cardSensor-bigcontainer">
            <div
              style={{
                paddingLeft: "80px",
                verticalAlign: "center",
                paddingTop: "30px",
              }}
            >
              <Temperature
                value={sensordata.temperature}
                height={210}
                maxValue={100}
              />
            </div>
            <Typography
              align="center"
              variant="subtitle1"
              fontWeight={1000}
              style={{
                // opacity: collapsed ? 0 : 0.7,
                letterSpacing: "0.5px",
                padding: "10px",
              }}
            >
              {sensordata.temperature} °C
            </Typography>
          </div>

          <Typography
            align="center"
            variant="subtitle1"
            fontWeight={1000}
            style={{
              // opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
              padding: "10px",
            }}
          >
            Temperature
          </Typography>
        </div>

        <div className="child">
          {/* <div style={appContainer}> */}
          <div className="cardSensor-bigcontainer">
            <div
              style={{
                paddingLeft: "20px",
                verticalAlign: "center",
                paddingTop: "30px",
              }}
            >
              {/* <div style={{alignItems: 'center', verticalAlign: 'center'}}> */}
              {/* <Humidity value={sensordata.humidity} height={100} /> */}
              <PM value={sensordata.vibration} height={200} />
              {/* <Intensity/> */}
            </div>

            <Typography
              align="center"
              variant="subtitle1"
              fontWeight={1000}
              style={{
                // opacity: collapsed ? 0 : 0.7,
                letterSpacing: "0.5px",
                padding: "10px",
              }}
            >
              {sensordata.vibration}
            </Typography>
          </div>
          <Typography
            align="center"
            variant="subtitle1"
            fontWeight={1000}
            style={{
              // opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
              padding: "10px",
            }}
          >
            Vibration
          </Typography>
        </div>

        <div className="child">
          {/* <div style={appContainer}> */}
          <div className="cardSensor-bigcontainer">
            <div
              style={{
                paddingLeft: "30px",
                verticalAlign: "center",
                paddingTop: "30px",
              }}
            >
              {/* <div style={{alignItems: 'center', verticalAlign: 'center'}}> */}
              <PM value={sensordata.tiltMeter} height={200} />
            </div>

            <Typography
              align="center"
              variant="subtitle1"
              fontWeight={1000}
              style={{
                // opacity: collapsed ? 0 : 0.7,
                letterSpacing: "0.5px",
                padding: "10px",
              }}
            >
              {sensordata.tiltMeter}
            </Typography>
          </div>
          <Typography
            align="center"
            variant="subtitle1"
            fontWeight={1000}
            style={{
              // opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
              padding: "10px",
            }}
          >
            TiltMeter
          </Typography>
        </div>

        <div className="child">
          {/* <div style={appContainer}> */}
          <div className="cardSensor-bigcontainer">
            <div
              style={{
                paddingLeft: "5px",
                verticalAlign: "center",

                paddingTop: "10px",
              }}
            >
              <ProgressBar
                key={1}
                LabelName="X-axis"
                bgcolor={"#6a1b9a"}
                completed={57}
              />

              <ProgressBar
                key={2}
                LabelName="Y-axis"
                bgcolor={"#00695c"}
                completed={33}
              />

              <ProgressBar
                key={3}
                LabelName="Z-axis"
                bgcolor={"#ef6c00"}
                completed={46}
              />

              {/* <div style={{alignItems: 'center', verticalAlign: 'center'}}> */}
              {/* <Electricity value={sensordata.co2 / 10} height={200} /> */}
            </div>

            {/* <Typography
            align="center"
            variant="subtitle1"
            fontWeight={1000}
            style={{
              // opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
              padding: "10px",
            }}
          >
            {sensordata.co2}
          </Typography> */}
          </div>
          <Typography
            align="center"
            variant="subtitle1"
            fontWeight={1000}
            style={{
              // opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
              padding: "10px",
            }}
          >
            Accelerometer
          </Typography>
        </div>

        <div className="child">
          {/* <div style={appContainer}> */}
          <div className="cardSensor-bigcontainer">
            {/* <div
            style={{
              paddingLeft: "5px",
              verticalAlign: "center",
              paddingTop: "10px",
            }}
          > */}
            {/* <ProgressBar
              key={1}
              LabelName="X-axis"
              bgcolor={"#6a1b9a"}
              completed={57}
            /> */}
            {/* <div style={{alignItems: 'center', verticalAlign: 'center'}}> */}
            {/* <Electricity value={sensordata.co2 / 10} height={200} /> */}
            {/* </div> */}

            <Typography
              align="center"
              variant="subtitle1"
              fontWeight={1000}
              fontSize={45}
              style={{
                // opacity: collapsed ? 0 : 0.7,
                letterSpacing: "1.5px",
                // padding: "10px",
                paddingTop: "80px",
                color: "red",
              }}
            >
              {-0.223}
            </Typography>

            <Typography
              align="center"
              variant="subtitle1"
              fontWeight={1000}
              fontSize={20}
              style={{
                // opacity: collapsed ? 0 : 0.7,
                letterSpacing: "1.5px",
                // padding: "10px",
                paddingTop: "80px",
              }}
            >
              in mm
            </Typography>
          </div>
          <Typography
            align="center"
            variant="subtitle1"
            fontWeight={1000}
            style={{
              // opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
              padding: "10px",
            }}
          >
            Deflection
          </Typography>
        </div>

        {/* <Line data={chartData} /> */}

        {/* <div className="Chart-bigcontainer"> */}
        {/* {items4 && <DynamicChart items={items4} />} */}
        {/* </div> */}
      </div>
    </section>
  );
};

export default ShmSensorBox;

import React, { useState, useEffect, useRef } from "react";

// import { Temperature } from "react-environment-chart";
// import { Humidity } from "react-environment-chart";
// import { PM } from "react-environment-chart";
// import { Electricity } from "react-environment-chart";
// import { Intensity } from "react-environment-chart";
// import Typography from "@mui/material/Typography";
import { GiComputerFan, GiCeilingLight } from "react-icons/gi";

// import { TbAirConditioning, TbBold } from "react-icons/tb";
// import { MdOutlineAir } from "react-icons/md";

// import { SlEnergy } from "react-icons/sl";

import { HiLightBulb, HiOutlineLightBulb } from "react-icons/hi";
import { PiFanFill } from "react-icons/pi";

import { SiHomeassistant } from "react-icons/si";

import { BsLampFill } from "react-icons/bs";

// import { IoWifiOutline } from "react-icons/io5";

// import { RiHomeWifiLine } from "react-icons/ri";

// import { WiHumidity } from "react-icons/wi";

// import myGif502 from "../images/home4_small.png";

// import { useProSidebar } from "react-pro-sidebar";

import { useAuthContext } from "../hooks/useAuthContext";
// import { useSensorDatasContext } from "../hooks/useSensorDatasContext";
// import { green } from "@mui/material/colors";

// import moment from "moment";

import "./Sw4r.css";

// import ToggleSwitch from "./components/ToggleSwitch";

// import Switch from "react-button-switch";
import Toggle from "react-styled-toggle";

// import { useAuthContext } from "../hooks/useAuthContext"
// import { useSensorDatasContext } from "../hooks/useSensorDatasContext"

import io from "socket.io-client";

// import 'bootstrap/dist/css/bootstrap.css';
// // Put any other imports below so that CSS from your
// // components takes precedence over default styles.

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import SliderValueLabel from "@mui/material/Slider/SliderValueLabel";

import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
// import Switch from "@mui/joy/Switch";

import Switch from "react-switch";
// import Switch from "@mui/material/Switch";

const baseUrl = process.env.REACT_APP_API_URL;

const socket = io.connect(baseUrl);

// let device;
// let deviceType;
// let temp = 45;
// let humi = 78;
// let lux = 150;
// let co2 = 180;

// function valuetext(value: number) {
//   return `${value}°C`;
// }

const Sw4r = ({ deviceID }) => {
  let [fan1RegulatorButton, setFan1RegulatorButton] = useState(0);

  // const [buttonIconcolor, setbuttonIconcolor] = useState("black");

  // const [temperatureT, settemperatureT] = useState(62);
  // const [humidityT, sethumidityT] = useState(72);
  // const [luxT, setluxT] = useState(82);
  // const [co2T, setco2T] = useState(92);

  // console.log("saroj deviceID:")
  // console.log(deviceID)

  // let [sensordata, setsensordata] = useState(
  //   '{"deviceID": "VBD10001","deviceType": "bioflyCM","temperature": "31","humidity": "61","lux": "1800","co2": "140","timestamp": "1679764210"}'
  // );

  const { user } = useAuthContext();

  // console.log("user")
  // console.log(user)

  // console.log("user.userName")
  // console.log(user.userName)

  const [CurrentDataState, setCurrentDataState] = useState({});

  const updateEzymoteHA3L1F = async (
    deviceID,
    deviceType,
    messageType,
    csq,
    load1,
    load2,
    load3,
    fan1,
    fan1Speed,
    warning,
    error,
    firmwareVersion,
    timestamp
  ) => {
    // const apis1 =
    //   `${baseUrl}/api/sensorDatas/ezymoteHA3L1F/` + deviceID + "/" + "1";

    // const response1 = await fetch(apis1, {
    //   headers: { Authorization: `Bearer ${user.token}` },
    // });
    // const json1 = await response1.json();
    // if (response1.ok) {
    //   const json2 = json1[0];
    //   console.log("json2:");
    //   console.log(json2);
    //   // setsensordata(json1);
    //   setCurrentDataState(json2);

    // console.log(" CurrentDataState.fan1:", CurrentDataState.fan1);
    // console.log(" CurrentDataState.fan1:", json2.fan1);

    // console.log("load1", load1);

    // const sss= JSON.stringify({ deviceID, deviceType, temperatureT, humidityT, luxT, co2T, ctimestamp })
    // console.log("sss:")
    // console.log(sss)
    // const apis = "http://192.46.211.71:4000/api/sensorDatas/ezymoteHA";
    // }

    // console.log("CurrentDataState:", CurrentDataState);

    // const CurrentDataStateLoad1 = CurrentDataState.load1;
    // const CurrentDataState1 = CurrentDataState;

    // const apis4 = `${baseUrl}/api/sensorDatas/ezymoteHA3L1FMQTT`;

    // const response4 = await fetch(apis4, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   // headers: { 'Authorization': `Bearer ${user.token}` },
    //   body: JSON.stringify({
    //     deviceID,
    //     deviceType,
    //     messageType,
    //     load1,
    //     load2,
    //     load3,
    //     fan1,
    //     fan1Speed,
    //     timestamp,
    //   }),
    // });

    // const json4 = await response4.json();
    // console.log("json4:", json4);

    // if (response4.ok) {
    //   console.log("Save success.");
    //   // alert("Save success.")
    // } else {
    //   console.log("Save error");
    // }

    // const sendMQTTmsgtoDevice = async (
    //   deviceID,
    //   deviceType,
    //   messageType,
    //   load1,
    //   load2,
    //   load3,
    //   fan1,
    //   fan1Speed,
    //   timestamp
    // ) => {
    //   // const sss= JSON.stringify({ deviceID, deviceType, temperatureT, humidityT, luxT, co2T, ctimestamp })
    //   // console.log("sss:")
    //   // console.log(sss)
    // const apis = "http://192.46.211.71:4000/api/sensorDatas/ezymoteHA";

    const apis3 = `${baseUrl}/api/sensorDatas/ezymoteHA3L1FMQTT`;
    const response3 = await fetch(apis3, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // headers: { 'Authorization': `Bearer ${user.token}` },
      body: JSON.stringify({
        deviceID,
        deviceType,
        messageType,
        csq,
        load1,
        load2,
        load3,
        fan1,
        fan1Speed,
        warning,
        error,
        firmwareVersion,
        timestamp,
      }),
    });
    const json3 = await response3.json();
    // console.log("json3:", json3);
    if (response3.ok) {
      // console.log("Save success.");
      // alert("Save success.")
    } else {
      // console.log("Save error");
    }
  };

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    console.log("Only once execution here only...");

    // const apis =
    //   "http://192.46.211.71:4000/api/sensorDatas/EzymoteHA/869523050076489/1";

    // const apis = `${baseUrl}/api/sensorDatas/EzymoteHA/869523050076489/1`;

    // const apis =
    //   `${baseUrl}/api/sensorDatas/ezymoteHA3L1F/` + deviceID + "/" + "1";

    // // console.log("apis:", apis);

    // fetch(apis, {
    //   headers: { Authorization: `Bearer ${user.token}` },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     // const data111 = data[0];
    //     // console.log("data111:", data111);
    //     console.log("data[0]:", data[0]);
    //     setCurrentDataState(data[0]);

    //     if (data[0].load1 === "on") {
    //       console.log("load1 is on");

    //       setLoad1Button(true);
    //       setLoad1ButtonIconcolor("#08C2F3");
    //     } else {
    //       console.log("load1 is off");

    //       setLoad1Button(false);
    //       setLoad1ButtonIconcolor("gray");
    //     }

    //     if (data[0].load2 === "on") {
    //       console.log("load2 is on");

    //       setLoad2Button(true);
    //       setLoad2ButtonIconcolor("#08C2F3");
    //     } else {
    //       console.log("load2 is off");

    //       setLoad2Button(false);
    //       setLoad2ButtonIconcolor("gray");
    //     }

    //     if (data[0].load3 === "on") {
    //       console.log("load3 is on");

    //       setLoad3Button(true);
    //       setLoad3ButtonIconcolor("#08C2F3");
    //     } else {
    //       console.log("load3 is off");

    //       setLoad3Button(false);
    //       setLoad3ButtonIconcolor("gray");
    //     }

    //     if (data[0].fan1 === "on") {
    //       console.log("fan1 is on");

    //       setFan1Button(true);
    //       setFan1ButtonIconcolor("#08C2F3");
    //     } else {
    //       console.log("fan1 is off");

    //       setFan1Button(false);
    //       setFan1ButtonIconcolor("gray");
    //     }
    //   });

    fetchSensorDataDetail111(deviceID);
  }, [fan1RegulatorButton]);

  const fetchSensorDataDetail111 = async (deviceID) => {
    const apis =
      `${baseUrl}/api/sensorDatas/ezymoteHA3L1F/` + deviceID + "/" + "1";

    const response = await fetch(apis, {
      headers: { Authorization: `Bearer ${user.token}` },
    });
    const json = await response.json();
    if (response.ok) {
      const json1 = json[0];
      // console.log("json1:");
      // console.log(json1);
      // setsensordata(json1);
      setCurrentDataState(json1);

      if (json1.load1 === "on") {
        // console.log("load1 is on");

        setLoad1Button(true);
        setLoad1ButtonIconcolor("#08C2F3");
      } else {
        // console.log("load1 is off");

        setLoad1Button(false);
        setLoad1ButtonIconcolor("gray");
      }

      if (json1.load2 === "on") {
        // console.log("load2 is on");

        setLoad2Button(true);
        setLoad2ButtonIconcolor("#08C2F3");
      } else {
        // console.log("load2 is off");

        setLoad2Button(false);
        setLoad2ButtonIconcolor("gray");
      }

      if (json1.load3 === "on") {
        // console.log("load3 is on");

        setLoad3Button(true);
        setLoad3ButtonIconcolor("#08C2F3");
      } else {
        // console.log("load3 is off");

        setLoad3Button(false);
        setLoad3ButtonIconcolor("gray");
      }

      if (json1.fan1 === "on") {
        // console.log("fan1 is on");

        setFan1Button(true);
        setFan1ButtonIconcolor("#08C2F3");
      } else {
        // console.log("fan1 is off");

        setFan1Button(false);
        setFan1ButtonIconcolor("gray");
      }

      setFan1RegulatorButton(json1.fan1Speed);
      // console.log("fan1RegulatorButton1: ", fan1RegulatorButton);
    }
    return json;
  };

  useEffect(() => {
    socket.on(deviceID, (json1) => {
      // console.log("Receive_message: ", json1);

      setCurrentDataState(json1);

      if (json1.load1 === "on") {
        // console.log("load1 is on");

        setLoad1Button(true);
        setLoad1ButtonIconcolor("#08C2F3");
      } else {
        // console.log("load1 is off");

        setLoad1Button(false);
        setLoad1ButtonIconcolor("gray");
      }

      if (json1.load2 === "on") {
        // console.log("load2 is on");

        setLoad2Button(true);
        setLoad2ButtonIconcolor("#08C2F3");
      } else {
        // console.log("load2 is off");

        setLoad2Button(false);
        setLoad2ButtonIconcolor("gray");
      }

      if (json1.load3 === "on") {
        // console.log("load3 is on");

        setLoad3Button(true);
        setLoad3ButtonIconcolor("#08C2F3");
      } else {
        // console.log("load3 is off");

        setLoad3Button(false);
        setLoad3ButtonIconcolor("gray");
      }

      if (json1.fan1 === "on") {
        // console.log("fan1 is on");

        setFan1Button(true);
        setFan1ButtonIconcolor("#08C2F3");
      } else {
        // console.log("fan1 is off");

        setFan1Button(false);
        setFan1ButtonIconcolor("gray");
      }

      // console.log("json1.fan1Speed:", json1.fan1Speed);
      setFan1RegulatorButton(json1.fan1Speed);

      // console.log("fan1RegulatorButton11: ", fan1RegulatorButton);
    });
  }, [socket]);

  // useEffect(() => {
  // read merged item
  // const user =   (AsyncStorage.getItem('user'))
  // const user = getData();
  // // const user =  JSON.parse(AsyncStorage.getItem('user'))
  // console.log("user112: ");
  // console.log(user);
  // const user = JSON.parse(localStorage.getItem('user'))
  // if (user) {
  // dispatch({ type: 'LOGIN', payload: user })
  // console.log("user11: ");
  // console.log(user);
  // console.log('----------------------------------------')
  // console.log('windowDimensions: ')
  // console.log(windowDimensions)
  // console.log('screenDimensions: ')
  // console.log(screenDimensions)
  // console.log('windowDimensions: ')
  // console.log(dimensions.window)
  // console.log('windowDimensions: ')
  // console.log(dimensions.screen)
  // console.log('----------------------------------------')
  // client.connect();
  // }
  // client.connect({
  //   onSuccess: client.onConnect,
  //   useSSL: false,
  //   userName: "john_doe",
  //   password: "EXAMPLE_PASSWORD",
  // });
  // console.log("Saroj1: ");
  // }, []);

  // const fetchSensorDataDetail = async (deviceID) => {
  //   const apis = "/api/sensorDatas/biofly/" + deviceID + "/" + "1";
  //   const response = await fetch(apis, {
  //     headers: { Authorization: `Bearer ${user.token}` },
  //   });
  //   const json = await response.json();
  //   if (response.ok) {
  //     const json1 = json[0];
  //     // console.log("json1:");
  //     // console.log(json1);
  //     setsensordata(json1);
  //   }
  //   return json;
  // };

  // const dataFetchedRef = useRef(false);

  // useEffect(() => {
  //   if (dataFetchedRef.current) return;
  //   dataFetchedRef.current = true;
  //   // console.log('Only once execution here only...');

  //   // fetchSensorDataDetail(deviceID);
  // }, []);

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     // const res = await fetch(`https://jsonplaceholder.typicode.com/posts`);
  //     // const json = fetchSensorDataDetail(deviceID);
  //   }, 10000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  // const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();

  // const [airConditionerButtonIconcolor, setAirConditionerButtonIconcolor] =
  //   useState("green");
  // const [exhaustFan1Buttoncolor, setExhaustFan1ButtonIconcolor] =
  //   useState("green");
  // const [exhaustFan2Buttoncolor, setExhaustFan2ButtonIconcolor] =
  //   useState("green");

  // const [exhaustFan1Button, setExhaustFan1Button] = useState(false);
  // const ExhaustFan1Buttonevent = (nextChecked) => {
  //   setExhaustFan1Button(nextChecked);

  //   // console.log("exhaustFan1Button: ");
  //   // console.log(exhaustFan1Button);

  //   if (!exhaustFan1Button) {
  //     setExhaustFan1ButtonIconcolor("red");
  //     console.log("Exhaust Fan1 on");
  //   } else {
  //     setExhaustFan1ButtonIconcolor("green");
  //     console.log("Exhaust Fan1 off");
  //   }
  //   //   sendToDevice()
  // };

  // const [exhaustFan2Button, setExhaustFan2Button] = useState(false);
  // const ExhaustFan2Buttonevent = (nextChecked) => {
  //   setExhaustFan2Button(nextChecked);

  //   // console.log("exhaustFan2Button: ");
  //   // console.log(exhaustFan2Button);

  //   if (!exhaustFan2Button) {
  //     setExhaustFan2ButtonIconcolor("red");
  //     // console.log("Exhaust Fan2 on");
  //   } else {
  //     setExhaustFan2ButtonIconcolor("green");
  //     // console.log("Exhaust Fan2 off");
  //   }
  //   //   sendToDevice()
  // };

  // const [airConditionerButton, setAirConditionerButton] = useState(false);
  // const AirConditionerButtonevent = (nextChecked) => {
  //   setAirConditionerButton(nextChecked);

  //   // console.log("airConditionerButton: ");
  //   // console.log(airConditionerButton);

  //   if (!airConditionerButton) {
  //     setAirConditionerButtonIconcolor("#08C2F3");
  //     // console.log("AirConditioner on");
  //   } else {
  //     setAirConditionerButtonIconcolor("gray");
  //     // console.log("AirConditioner off");
  //   }
  // };

  const [load1ButtonIconcolor, setLoad1ButtonIconcolor] = useState("gray");
  const [load1Button, setLoad1Button] = useState(false);

  const Load1Buttonevent = (nextChecked) => {
    setLoad1Button(nextChecked);

    // console.log("load1Button: ");
    // console.log(load1Button);

    if (!load1Button) {
      setLoad1ButtonIconcolor("#08C2F3");
      // console.log("load1Button on");

      // console.log("CurrentDataState: ", CurrentDataState);
      const CurrentDataState1 = CurrentDataState;
      CurrentDataState1.load1 = "on";

      updateEzymoteHA3L1F(
        CurrentDataState1.deviceID,
        CurrentDataState1.deviceType,
        CurrentDataState1.messageType,
        CurrentDataState1.csq,
        CurrentDataState1.load1,
        CurrentDataState1.load2,
        CurrentDataState1.load3,
        CurrentDataState1.fan1,
        CurrentDataState1.fan1Speed,
        CurrentDataState1.warning,
        CurrentDataState1.error,
        CurrentDataState1.firmwareVersion,
        CurrentDataState1.timestamp
      );
    } else {
      setLoad1ButtonIconcolor("gray");
      // console.log("load1Button off");

      // console.log("CurrentDataState: ", CurrentDataState);
      const CurrentDataState1 = CurrentDataState;
      CurrentDataState1.load1 = "off";

      updateEzymoteHA3L1F(
        CurrentDataState1.deviceID,
        CurrentDataState1.deviceType,
        CurrentDataState1.messageType,
        CurrentDataState1.csq,
        CurrentDataState1.load1,
        CurrentDataState1.load2,
        CurrentDataState1.load3,
        CurrentDataState1.fan1,
        CurrentDataState1.fan1Speed,
        CurrentDataState1.warning,
        CurrentDataState1.error,
        CurrentDataState1.firmwareVersion,
        CurrentDataState1.timestamp
      );
    }
  };

  const [load2ButtonIconcolor, setLoad2ButtonIconcolor] = useState("gray");
  const [load2Button, setLoad2Button] = useState(false);

  const Load2Buttonevent = (nextChecked) => {
    setLoad2Button(nextChecked);

    // console.log("load2Button: ");
    // console.log(load2Button);

    if (!load2Button) {
      setLoad2ButtonIconcolor("#08C2F3");
      // console.log("load2Button on");

      // console.log("CurrentDataState: ", CurrentDataState);
      const CurrentDataState1 = CurrentDataState;
      CurrentDataState1.load2 = "on";

      updateEzymoteHA3L1F(
        CurrentDataState1.deviceID,
        CurrentDataState1.deviceType,
        CurrentDataState1.messageType,
        CurrentDataState1.csq,
        CurrentDataState1.load1,
        CurrentDataState1.load2,
        CurrentDataState1.load3,
        CurrentDataState1.fan1,
        CurrentDataState1.fan1Speed,
        CurrentDataState1.warning,
        CurrentDataState1.error,
        CurrentDataState1.firmwareVersion,
        CurrentDataState1.timestamp
      );
    } else {
      setLoad2ButtonIconcolor("gray");
      // console.log("load2Button off");

      // console.log("CurrentDataState: ", CurrentDataState);
      const CurrentDataState1 = CurrentDataState;
      CurrentDataState1.load2 = "off";

      updateEzymoteHA3L1F(
        CurrentDataState1.deviceID,
        CurrentDataState1.deviceType,
        CurrentDataState1.messageType,
        CurrentDataState1.csq,
        CurrentDataState1.load1,
        CurrentDataState1.load2,
        CurrentDataState1.load3,
        CurrentDataState1.fan1,
        CurrentDataState1.fan1Speed,
        CurrentDataState1.warning,
        CurrentDataState1.error,
        CurrentDataState1.firmwareVersion,
        CurrentDataState1.timestamp
      );
    }
  };

  const [load3ButtonIconcolor, setLoad3ButtonIconcolor] = useState("gray");
  const [load3Button, setLoad3Button] = useState(false);

  const Load3Buttonevent = (nextChecked) => {
    setLoad3Button(nextChecked);

    // console.log("load3Button: ");
    // console.log(load3Button);

    if (!load3Button) {
      setLoad3ButtonIconcolor("#08C2F3");
      // console.log("load3Button on");

      // console.log("CurrentDataState: ", CurrentDataState);
      const CurrentDataState1 = CurrentDataState;
      CurrentDataState1.load3 = "on";

      updateEzymoteHA3L1F(
        CurrentDataState1.deviceID,
        CurrentDataState1.deviceType,
        CurrentDataState1.messageType,
        CurrentDataState1.csq,
        CurrentDataState1.load1,
        CurrentDataState1.load2,
        CurrentDataState1.load3,
        CurrentDataState1.fan1,
        CurrentDataState1.fan1Speed,
        CurrentDataState1.warning,
        CurrentDataState1.error,
        CurrentDataState1.firmwareVersion,
        CurrentDataState1.timestamp
      );
    } else {
      setLoad3ButtonIconcolor("gray");
      // console.log("load3Button off");

      // console.log("CurrentDataState: ", CurrentDataState);
      const CurrentDataState1 = CurrentDataState;
      CurrentDataState1.load3 = "off";

      updateEzymoteHA3L1F(
        CurrentDataState1.deviceID,
        CurrentDataState1.deviceType,
        CurrentDataState1.messageType,
        CurrentDataState1.csq,
        CurrentDataState1.load1,
        CurrentDataState1.load2,
        CurrentDataState1.load3,
        CurrentDataState1.fan1,
        CurrentDataState1.fan1Speed,
        CurrentDataState1.warning,
        CurrentDataState1.error,
        CurrentDataState1.firmwareVersion,
        CurrentDataState1.timestamp
      );
    }
  };

  const [fan1ButtonIconcolor, setFan1ButtonIconcolor] = useState("gray");
  const [fan1Button, setFan1Button] = useState(false);

  const fan1Buttonevent = (nextChecked) => {
    setFan1Button(nextChecked);

    // setChecked(event.target.checked)

    // console.log("fan1Button: ");
    // console.log(fan1Button);

    if (!fan1Button) {
      setFan1ButtonIconcolor("#08C2F3");
      // console.log("load4Button on");

      // console.log("CurrentDataState: ", CurrentDataState);
      const CurrentDataState1 = CurrentDataState;
      CurrentDataState1.fan1 = "on";

      updateEzymoteHA3L1F(
        CurrentDataState1.deviceID,
        CurrentDataState1.deviceType,
        CurrentDataState1.messageType,
        CurrentDataState1.csq,
        CurrentDataState1.load1,
        CurrentDataState1.load2,
        CurrentDataState1.load3,
        CurrentDataState1.fan1,
        CurrentDataState1.fan1Speed,
        CurrentDataState1.warning,
        CurrentDataState1.error,
        CurrentDataState1.firmwareVersion,
        CurrentDataState1.timestamp
      );
    } else {
      setFan1ButtonIconcolor("gray");
      // console.log("load4Button off");

      // console.log("CurrentDataState: ", CurrentDataState);
      const CurrentDataState1 = CurrentDataState;
      CurrentDataState1.fan1 = "off";

      updateEzymoteHA3L1F(
        CurrentDataState1.deviceID,
        CurrentDataState1.deviceType,
        CurrentDataState1.messageType,
        CurrentDataState1.csq,
        CurrentDataState1.load1,
        CurrentDataState1.load2,
        CurrentDataState1.load3,
        CurrentDataState1.fan1,
        CurrentDataState1.fan1Speed,
        CurrentDataState1.warning,
        CurrentDataState1.error,
        CurrentDataState1.firmwareVersion,
        CurrentDataState1.timestamp
      );
    }
  };

  const fanRegulator1Event = (nextChecked) => {
    setFan1RegulatorButton(nextChecked);

    // console.log("fan1RegulatorButton: ", fan1RegulatorButton);

    // const CurrentDataState1 = CurrentDataState;
    // CurrentDataState1.fan1Speed = fan1RegulatorButton;

    // updateEzymoteHA3L1F(
    //   CurrentDataState1.deviceID,
    //   CurrentDataState1.deviceType,
    //   CurrentDataState1.messageType,
    //   CurrentDataState1.csq,
    //   CurrentDataState1.load1,
    //   CurrentDataState1.load2,
    //   CurrentDataState1.load3,
    //   CurrentDataState1.fan1,
    //   CurrentDataState1.fan1Speed,
    //   CurrentDataState1.warning,
    //   CurrentDataState1.error,
    //   CurrentDataState1.firmwareVersion,
    //   CurrentDataState1.timestamp
    // );
  };

  const fanRegulator1Event1 = (nextChecked) => {
    // console.log("fan1RegulatorButton1: ", fan1RegulatorButton);

    const CurrentDataState1 = CurrentDataState;
    CurrentDataState1.fan1Speed = fan1RegulatorButton;

    updateEzymoteHA3L1F(
      CurrentDataState1.deviceID,
      CurrentDataState1.deviceType,
      CurrentDataState1.messageType,
      CurrentDataState1.csq,
      CurrentDataState1.load1,
      CurrentDataState1.load2,
      CurrentDataState1.load3,
      CurrentDataState1.fan1,
      CurrentDataState1.fan1Speed,
      CurrentDataState1.warning,
      CurrentDataState1.error,
      CurrentDataState1.firmwareVersion,
      CurrentDataState1.timestamp
    );
  };

  // const [fan1ButtonIconcolor, setFan1ButtonIconcolor] = useState("gray");
  // const [fan1Button, setFan1Button] = useState(false);

  // const fanRegulator1Event = (speedvalue) => {
  //   console.log("fanRegulator1Event");
  //   console.log(speedvalue);

  // setFan1Button(nextChecked);
  // console.log("load4Button: ");
  // console.log(load4Button);
  // if (!fan1Button) {
  //   setFan1ButtonIconcolor("#08C2F3");
  //   // console.log("load4Button on");
  //   // console.log("CurrentDataState: ", CurrentDataState);
  //   const CurrentDataState1 = CurrentDataState;
  //   CurrentDataState1.fan1 = "on";
  //   updateEzymoteHA3L1F(
  //     CurrentDataState1.deviceID,
  //     CurrentDataState1.deviceType,
  //     CurrentDataState1.messageType,
  //     CurrentDataState1.csq,
  //     CurrentDataState1.load1,
  //     CurrentDataState1.load2,
  //     CurrentDataState1.load3,
  //     CurrentDataState1.fan1,
  //     CurrentDataState1.fan1Speed,
  //     CurrentDataState1.warning,
  //     CurrentDataState1.error,
  //     CurrentDataState1.firmwareVersion,
  //     CurrentDataState1.timestamp
  //   );
  // } else {
  //   setFan1ButtonIconcolor("gray");
  //   // console.log("load4Button off");
  //   // console.log("CurrentDataState: ", CurrentDataState);
  //   const CurrentDataState1 = CurrentDataState;
  //   CurrentDataState1.fan1 = "off";
  //   updateEzymoteHA3L1F(
  //     CurrentDataState1.deviceID,
  //     CurrentDataState1.deviceType,
  //     CurrentDataState1.messageType,
  //     CurrentDataState1.csq,
  //     CurrentDataState1.load1,
  //     CurrentDataState1.load2,
  //     CurrentDataState1.load3,
  //     CurrentDataState1.fan1,
  //     CurrentDataState1.fan1Speed,
  //     CurrentDataState1.warning,
  //     CurrentDataState1.error,
  //     CurrentDataState1.firmwareVersion,
  //     CurrentDataState1.timestamp
  //   );
  // }
  // };

  // const [temperatureValue, setTemperatureValue] = useState(25);
  // const [temperatureColor, setTemperatureColor] = useState("neutral");

  // const fetchSensorData = async (
  //   deviceID,
  //   deviceType,
  //   temperatureT,
  //   humidityT,
  //   luxT,
  //   co2T,
  //   ctimestamp
  // ) => {
  //   // const sss= JSON.stringify({ deviceID, deviceType, temperatureT, humidityT, luxT, co2T, ctimestamp })
  //   // console.log("sss:")
  //   // console.log(sss)
  //   const apis = "/api/sensorDatas/bioflymqtt";
  //   const response = await fetch(apis, {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     // headers: { 'Authorization': `Bearer ${user.token}` },
  //     body: JSON.stringify({
  //       deviceID,
  //       deviceType,
  //       temperatureT,
  //       humidityT,
  //       luxT,
  //       co2T,
  //       ctimestamp,
  //     }),
  //   });

  //   const json = await response.json();
  //   // console.log("jso:", json)

  //   if (response.ok) {
  //     // console.log("ok");
  //     // alert("Save success.")
  //   } else {
  //     // console.log("err");
  //   }
  // };

  // const increasetemperatureT = () => {
  //   const newtemperatureT = temperatureT + 1;
  //   settemperatureT(newtemperatureT);
  //   // if (newTemperature >= 15) {
  //   // 	setTemperatureColor('hot');
  //   // }
  // };

  // const decreasetemperatureT = () => {
  //   const newtemperatureT = temperatureT - 1;
  //   settemperatureT(newtemperatureT);
  //   // if (newTemperature < 15) {
  //   // 	setTemperatureColor('cold');
  //   // }
  // };

  // const savetemperatureT = () => {
  //   const ctimestamp = Date.now().toString();
  //   fetchSensorData(
  //     sensordata.deviceID,
  //     sensordata.deviceType,
  //     temperatureT.toString(),
  //     humidityT.toString(),
  //     luxT.toString(),
  //     co2T.toString(),
  //     ctimestamp
  //   );
  // };

  // const increasehumidityT = () => {
  //   const newhumidityT = humidityT + 1;
  //   sethumidityT(newhumidityT);
  //   // if (newTemperature >= 15) {
  //   // 	setTemperatureColor('hot');
  //   // }
  // };

  // const decreasehumidityT = () => {
  //   const newhumidityT = humidityT - 1;
  //   sethumidityT(newhumidityT);
  //   // if (newTemperature < 15) {
  //   // 	setTemperatureColor('cold');
  //   // }
  // };

  // const savehumidityT = () => {
  //   const ctimestamp = Date.now().toString();
  //   fetchSensorData(
  //     sensordata.deviceID,
  //     sensordata.deviceType,
  //     temperatureT.toString(),
  //     humidityT.toString(),
  //     luxT.toString(),
  //     co2T.toString(),
  //     ctimestamp
  //   );
  // };

  // const increaseco2T = () => {
  //   const newco2T = co2T + 1;
  //   setco2T(newco2T);
  //   // if (newTemperature >= 15) {
  //   // 	setTemperatureColor('hot');
  //   // }
  // };

  // const decreaseco2T = () => {
  //   const newco2T = co2T - 1;
  //   setco2T(newco2T);
  //   // if (newTemperature < 15) {
  //   // 	setTemperatureColor('cold');
  //   // }
  // };

  // const saveco2T = () => {
  //   const ctimestamp = Date.now().toString();
  //   fetchSensorData(
  //     sensordata.deviceID,
  //     sensordata.deviceType,
  //     temperatureT.toString(),
  //     humidityT.toString(),
  //     luxT.toString(),
  //     co2T.toString(),
  //     ctimestamp
  //   );
  // };

  // const date = moment.unix(sensordata.timestamp).format("DD/MM/YYYY");
  // console.log(date);

  // const time = moment.unix(sensordata.timestamp).format("hh:mm:ss");
  // console.log(time);

  // const [datatime, setdatatime] = useState(0);

  // const [checked, setChecked] = React.useState(false);

  return (
    <div className="parent">
      {/* <center> <img src={myGif502} alt="my-gif" /></center> */}

      {/* <img src={myGif502} alt="my-gif" /> */}

      <div className="child1">
        <div className="homeautomation-container">
          {/* <center> */}
          {/* HiLightBulb, HiOutlineLightBulb */}
          <div align="right">
            {/* <p style={{ color: "black", fontSize: '20px', textAlign: 'left' }}>
 
 </p> */}

            <p
              style={{
                color: "black",
                fontSize: "20px",
                textAlign: "left",
                padding: "5px",
              }}
            >
              <SiHomeassistant
                size={60}
                style={{ color: "#08C2F3", fontSize: "50px" }}
              />
              Welcome, <b>{user.userName} </b>
            </p>

            <br></br>

            {/* <p style={{ color: "black", fontSize: "14px", textAlign: "left" }}> */}
            <center>Device ID: {CurrentDataState.deviceID}</center>

            <center>
              WiFi SS: {CurrentDataState.csq} 
            </center>
            {/* </p> */}
            {/* <IoWifiOutline
                size={50}
                style={{ color: "#08C2F3", fontSize: "50px" }}
              /> */}

            {/* <RiHomeWifiLine size={70} style={{ color: "#08C2F3", fontSize: '50px' }} /> */}
          </div>
        </div>
      </div>

      <div className="child1">
        <div className="homeautomation-buttoncontainer">
          <center>
            <GiCeilingLight
              size={40}
              style={{ color: load1ButtonIconcolor, fontSize: "50px" }}
            />

            <Switch
              checked={load1Button}
              onChange={Load1Buttonevent}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={12}
              width={40}
              className="react-switch"
              id="material-switch"
            />
          </center>
          <br></br>
          <center>
            <b> Ceiling Light </b>
          </center>
        </div>
      </div>

      <div className="child1">
        <div className="homeautomation-buttoncontainer">
          <center>
            <BsLampFill
              size={40}
              style={{ color: load2ButtonIconcolor, fontSize: "50px" }}
            />

            <Switch
              checked={load2Button}
              onChange={Load2Buttonevent}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={12}
              width={40}
              className="react-switch"
              id="material-switch"
            />
          </center>
          <br></br>
          <center>
            <b> Ceiling Light </b>
          </center>
        </div>
      </div>

      <div className="child1">
        <div className="homeautomation-buttoncontainer">
          <center>
            <HiLightBulb
              size={40}
              style={{ color: load3ButtonIconcolor, fontSize: "50px" }}
            />

            <Switch
              checked={load3Button}
              onChange={Load3Buttonevent}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={12}
              width={40}
              className="react-switch"
              id="material-switch"
            />

            {/* <div style={{ 
      display: 'block', width: 250, paddingLeft: 0 
    }}> 
      <h4>React Suite Slider Component</h4> <br></br> 
      <Slider 
        max={5} 
        min={0} 
        step={1} 
        defaultValue={3} 
        graduated 
        progress 
      /> 
    </div>  */}
          </center>
          <br></br>
          <center>
            <b> Ceiling Light </b>
          </center>
        </div>
      </div>

      <div className="child1">
        <div className="homeautomation-buttoncontainer">
          <center>
            <PiFanFill
              size={40}
              style={{ color: fan1ButtonIconcolor, fontSize: "50px" }}
            />

            <Switch
              checked={fan1Button}
              onChange={fan1Buttonevent}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={12}
              width={40}
              className="react-switch"
              id="material-switch"
            />
          </center>
          <br></br>
          <center>
            <b> Fan </b>
          </center>
        </div>
      </div>

      <div className="child1">
        <div className="homeautomation-fanregulatorcontainer">
          <center>
            {/* HiLightBulb, HiOutlineLightBulb */}
            {/* <HiLightBulb
              size={40}
              style={{ color: fan1ButtonIconcolor, fontSize: "50px" }}
            /> */}
            {/* <span>{load4Button ? "ON" : "OFF"}</span> */}
            {/* <Switch
              // onClick={ExhaustFan1Buttonevent}
              onChange={fan1Buttonevent}
              checked={fan1Button}
              className="react-switch"
            /> */}

            <Box sx={{ width: 250 }}>
              <Slider
                // checked={fan1Button}
                defaultValue={fan1RegulatorButton}
                // getAriaLabel={fanRegulator1Event}
                getAriaValueText={fanRegulator1Event}
                onChangeCommitted={fanRegulator1Event1}
                // getAriaLabel={value => console.log("value: ",value)}
                aria-label="FanSpeed"
                // value={fan1RegulatorButton}
                // getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                // shiftStep={1}
                step={1}
                marks
                min={0}
                max={5}
                // onChange={fanRegulator1Event(3)}
              />
            </Box>

            {/* <Toggle 
              // height={20}
              onChange={Load4Buttonevent}
              checked={load4Button} 
              // sliderHeight={12}
              // sliderWidth={12}
              // translate={19}
              // width={40}
              // labelLeft="ON"
            /> */}
          </center>
          {/* <br></br> */}
          <center>
            <b> Fan Speed </b>
            {}
          </center>
        </div>
      </div>
    </div>
  );
};

export default Sw4r;

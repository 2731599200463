import React from "react";
import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";

// pages & components
import Home from "./pages/Home";
// import Login from "./pages/Login";
// import Signin from "./pages/Signin";
import LoginPage from "./pages/LoginPage";
// import Signup from "./pages/Signup";
// import Signup1 from "./pages/Signup1";
import Signup2 from "./pages/Signup2";

// // import login from "./pages/Signin";

import Admin from "./pages/Admin";
import Graphs from "./pages/Graphs";
import Reports from "./pages/Reports";
import Support from "./pages/Support";

import ContactUs from "./pages/ContactUs";

import Notification from "./pages/Notification";

import BioflyDeviceDetail from "./pages/BioflyDeviceDetail";

import BioflyUserDetails from "./pages/BioflyUserDetails";
import BioflyDeviceCharts from "./pages/BioflyDeviceCharts";
import BioflyDataInTables from "./pages/BioflyDataInTables";

import EzytrackDeviceDetail from "./pages/EzytrackDeviceDetail";
import EzytrackUserDetails from "./pages/EzytrackUserDetails";

import EzymoteDeviceDetail from "./pages/EzymoteDeviceDetail";
import EzymoteUserDetails from "./pages/EzymoteUserDetails";

import ShmUserDetails from "./pages/ShmUserDetails";
import ShmDeviceCharts from "./pages/ShmDeviceCharts";
import ShmDeviceDetail from "./pages/ShmDeviceDetail";

// import Navbar from "./components/Navbar";

// // import Sidebar from "./components/Sidebarr";

// import Navbarr from "./components/Navbarr";

import ResponsiveAppBar from "./components/ResponsiveAppBar";

function App() {
  const { user } = useAuthContext();

  return (
    // <div
    // style={{
    //   backgroundColor: "cyan",
    // }}
    // >
    // {/* <Navbarr/> */}

    // {/* <div className="pagesma"> */}
    // {/* <div id="app" style={({ height: "100vh" }, { display: "flex" })}> */}
    // {/* <div> */}

    <BrowserRouter>
      {/* <Navbar /> */}

      {/* <div> */}

      {/* <Navbarr /> */}

      <ResponsiveAppBar />

      <Routes>
        {/* <Route
                path="/"
                element={user ? <Home /> : <Navigate to="/login" />}
              /> */}
        <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
        {/* 





<Route
  path="/EzytrackDeviceDetail"
  element={user ? <EzytrackDeviceDetail /> : <Navigate to="/" />}
/>



 */}

        <Route
          path="/BioflyDeviceDetail"
          element={user ? <BioflyDeviceDetail /> : <Navigate to="/" />}
        />
        <Route path="/Admin" element={user ? <Admin /> : <Navigate to="/" />} />
        <Route
          path="/Reports"
          element={user ? <Reports /> : <Navigate to="/" />}
        />
        <Route
          path="/Support"
          element={user ? <Support /> : <Navigate to="/" />}
        />
        <Route
          path="/Graphs"
          element={user ? <Graphs /> : <Navigate to="/" />}
        />
        <Route
          path="/BioflyDeviceCharts"
          element={user ? <BioflyDeviceCharts /> : <Navigate to="/" />}
        />
        <Route
          path="/BioflyDataInTables"
          element={user ? <BioflyDataInTables /> : <Navigate to="/" />}
        />
        <Route
          path="/ShmDeviceCharts"
          element={user ? <ShmDeviceCharts /> : <Navigate to="/" />}
        />

        <Route
          path="/ShmDeviceDetail"
          element={user ? <ShmDeviceDetail /> : <Navigate to="/" />}
        />
        <Route
          path="/BioflyUserDetails"
          element={user ? <BioflyUserDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/EzytrackUserDetails"
          element={user ? <EzytrackUserDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/EzytrackDeviceDetail"
          element={user ? <EzytrackDeviceDetail /> : <Navigate to="/" />}
        />

        <Route
          path="/EzymoteUserDetails"
          element={user ? <EzymoteUserDetails /> : <Navigate to="/" />}
        />

        <Route
          path="/EzymoteDeviceDetail"
          element={user ? <EzymoteDeviceDetail /> : <Navigate to="/" />}
        />

        <Route
          path="/ShmUserDetails"
          element={user ? <ShmUserDetails /> : <Navigate to="/" />}
        />
        {/* <Route
          path="/login"
          element={!user ? <Login /> : <Navigate to="/" />}
        /> */}
        {/* <Route
          path="/login"
          element={!user ? <Signin /> : <Navigate to="/" />}
        />  */}

        <Route
          path="/login"
          element={!user ? <LoginPage /> : <Navigate to="/" />}
        />

        {/* <Route
          path="/signup"
          element={!user ? <Signup /> : <Navigate to="/" />}
        /> */}

        {/* <Route
          path="/signup"
          element={!user ? <Signup1 /> : <Navigate to="/" />}
        /> */}
        <Route
          path="/signup"
          element={!user ? <Signup2 /> : <Navigate to="/" />}
        />

        <Route
          path="/ContactUs"
          element={user ? <ContactUs /> : <Navigate to="/" />}
        />
        <Route
          path="/Notification"
          element={user ? <Notification /> : <Navigate to="/" />}
        />
      </Routes>
    </BrowserRouter>

    // {/* // </div> */}
    // </div>

    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save1 to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;

import React, { useMemo, useState, useEffect, useRef } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

import { useNavigate } from "react-router-dom";

 


import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";


const deepMerge = require("deepmerge");

const baseUrl = process.env.REACT_APP_API_URL;

const Admin = () => {
  const { user } = useAuthContext();

  const columns = useMemo(
    () => [
      {
        accessorKey: "createdAt",
        header: "Created On",
      },
      // {
      //   accessorKey: "_id", //access nested data with dot notation
      //   header: "Id",
      // },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "userName",
        header: "User Name",
      },
      {
        accessorKey: "role",
        header: "Role",
      },
      {
        accessorKey: "activation",
        header: "Activation",
      },
      {
        accessorKey: "deviceType",
        header: "Device Type",
      },
      {
        accessorKey: "deviceIDDetails",
        header: "Device ID Details",
      },
    ],
    []
  );

  const dataFetchedRef = useRef(false);

  const [userDetails, setuserDetails] = useState([]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log("Admin Only once execution here only...");

    // const apis = "/api/deviceAssigned/" + user.email;

    // fetch(apis, {
    //   headers: { Authorization: `Bearer ${user.token}` },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     const data1 = data[0];
    //     console.log("data:", data1);
    //     console.log("data.role:", data1.role);
    //     console.log("data.deviceType:", data1.deviceType);
    //     // setItems(data);

    //     if (data1.deviceType === "admin") {
    //       // console.log("Navigate to admin panel");
    //       navigate("/admin");
    //     } else if (data1.deviceType === "client") {
    //       // console.log("deviceType is shm ");
    //       // navigate("/Maps");
    //     }

    //     // if (data1.deviceType === "biofly") {
    //     //   // console.log("deviceType is biofly ");
    //     //   // navigate("/BioflyUserDetails");
    //     //   // navigate("/BioflyUserDetails", {
    //     //   //   state: { json1: { data1 } },
    //     //   // });
    //     // }

    //     // if (data1.deviceType === "shm") {
    //     //   // console.log("deviceType is shm ");
    //     //   // navigate("/Maps");
    //     // }
    //   });

    const apis = `${baseUrl}/api/userDetail`;

    fetch(apis, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        // const data1 = data[0];
        // // console.log("data:", data1);
        // // console.log("data.role:", data1.role);
        // // console.log("data.deviceIDDetails:", data1.deviceIDDetails);
        // // setItems(data);
        setuserDetails(data);
      });
  }, []);

  return (
    <div className="mainPage">
      {/* <div> */}
      <div>Welcome to admin panel</div>
      {/* <div>Welcome to admin panel</div>
      <div>Welcome to admin panel</div> */}
      {/* <div>
        {userDetails.map((client) => {
          const list = (
            <>
              <ul>
                <li>Id: {client._id}</li>
                <li>UserName: {client.userName}</li>
                <li>Email: {client.email}</li>
              </ul>
              <hr />
            </>
          );
          return list;
        })}
      </div> 
      <center>All the Client details</center>
      {/* </h1> */}
      {/* <MaterialReactTable columns={columns} data={data} />; */}
      <MaterialReactTable columns={columns} data={userDetails} />;
      {/* </main> */}
    </div>
  );
};

export default Admin;

import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBTextArea,
  MDBBtn,
} from "mdb-react-ui-kit";

export default function App() {
  return (
    <div className="parent">
      <section className="border text-center mb-4">
        <h3 className="mb-10">Contact us</h3>
        <h5>
          Incase if you found any issue in the cloud, please report to us
          through email or any of the following way for faster resolve your
          issues.
        </h5>

        <br></br>
        <br></br>
        <div className="row">
          {/* <div className="col-lg-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1220.09124193182!2d-74.00599512526003!3d40.72586666928451!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2598f988156a9%3A0xd54629bdf9d61d68!2sBroadway-Lafayette%20St!5e0!3m2!1spl!2spl!4v1624523797308!5m2!1spl!2spl"
            className="h-100 w-100"
            style={{ border: "0" }}
            loading="lazy"
          ></iframe>
        </div> */}
          <div className="col-lg-7">
            <form>
              <div className="row">
                <div className="col-md-9">
                  <MDBRow className="mb-4">
                    <MDBCol>
                      <MDBInput label="First name" id="form3FirstName" />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput label="Email Address" id="form3Email" />
                    </MDBCol>
                  </MDBRow>
                  <MDBInput
                    type="text"
                    label="Subject"
                    id="form3Subject"
                    v-model="form3Subject"
                    wrapperClass="mb-4"
                  />
                  <MDBTextArea
                    label="Message"
                    id="form3Textarea"
                    wrapperClass="mb-4"
                  />
                  <MDBBtn color="primary" className="mb-4">
                    {" "}
                    Send{" "}
                  </MDBBtn>
                </div>
                <div className="col-md-3">
                  <ul className="list-unstyled">
                    <li>
                      <i className="fas fa-map-marker-alt fa-2x text-primary"></i>
                      <p>
                        <small>
                          Budheswari Colony, Bhubaneswar, Odisha 751006
                        </small>
                      </p>
                    </li>
                    <li>
                      <i className="fas fa-phone fa-2x text-primary"></i>
                      <p>
                        <small>+91 8270441234</small>
                      </p>
                    </li>
                    <li>
                      <i className="fas fa-envelope fa-2x text-primary"></i>
                      <p>
                        <small>sarojflame@gmail.com</small>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

// import React, { useState } from "react";
// import axios from "axios";

// const baseUrl = process.env.REACT_APP_API_URL;

// const ContactForm = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       // Send form data to the backend
//       const response = await axios.post(`${baseUrl}/send-email`, formData);
//       alert(response.data.message);
//     } catch (error) {
//       alert("There was an error sending the email.");
//     }
//   };

//   return (
//     <div className="parent">
//       <h2>Contact Us</h2>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>Name:</label>
//           <input
//             type="text"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Email:</label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Message:</label>
//           <textarea
//             name="message"
//             value={formData.message}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <button type="submit">Send</button>
//       </form>
//     </div>
//   );
// };

// export default ContactForm;

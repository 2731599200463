import { useLocation } from "react-router-dom";
import React from "react";
// import { useForm } from "react-hook-form";

// import '../index.css';

// import RegisterForm from "../components/RegisterForm.js";
// import BioflySensorBox from "../components/bioflySensorBox.js";

// import EzytrackSensorBox from "../components/EzytrackSensorBox.js";

// import EzytrackSensorBoxcopy from "../components/EzytrackSensorBoxcopy.js";

import { Route, Link, Routes, useNavigate } from "react-router-dom";

import { useAuthContext } from "../hooks/useAuthContext.js";

import Sw4r from "../components/Sw4r.js";

const baseUrl = process.env.REACT_APP_API_URL;

const EzymoteDeviceDetail = () => {
  const { user } = useAuthContext();

  const { state } = useLocation();
  const { id } = state;
  // console.log("id:");
  // console.log(id);

  // console.log("user:");
  // console.log(user);

  //   console.log("deviceIDDetails:");
  // console.log(id.deviceIDDetails);

  // console.log(id.state)

  // const location = useLocation();

  // console.log("location:")
  // console.log(location)

  // const [searchparams] = useSearchParams();

  // console.log(searchparams.get("id"));

  // console.log("deviceID:")
  // console.log(deviceID)

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="mainPage">
      {/* <> */}
      {/* <div>    view url search params </div> */}
      {/* id is {searchparams.state.id} */}
      {/* id is {location.state.deviceId} */}
      {/* </> */}
      {/* <button onClick={goBack}>Back</button> */}

      <Sw4r deviceID={id.deviceIDDetails} />
    </div>
  );
};

export default EzymoteDeviceDetail;

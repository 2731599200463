import React, { useState, useEffect, useRef } from "react";
import {
  // createSearchParams,
  // Link,
  // Route,
  // Routes,
  useNavigate,
  // useLocation,
} from "react-router-dom";

import { useAuthContext } from "../hooks/useAuthContext";

import "./BioflyUserDetails.css";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

const baseUrl = process.env.REACT_APP_API_URL;

const BioflyUserDetails = () => {
  const { user } = useAuthContext();

  // const { state } = useLocation();
  // const { json1 } = state;

  // const json1 = props.items[0];

  // const [count, setCount] = useState('');

  // const [dataas, setdataas] = useState("green");

  // let [data, setData] = useState(0);

  // const [users, setUsers] = useState([]);

  const [users, setUsers] = useState([]);

  // const fetchSensorDataDetail = async (deviceID) => {
  const navigate = useNavigate();

  // const number = "869523050076489";

  // const goBack = () => {

  const navigateToBioflySensorBox = (deviceIDDetails) => {
    // if (json1.deviceType === "biofly") {
    // console.log("deviceType is biofly ");
    navigate("/BioflyDeviceDetail", {
      state: { id: { deviceIDDetails } },
    });
    // }
    // if (json1.deviceType === "shm") {
    // console.log("deviceType is shm ");
    // navigate("/ShmDeviceDetail", {
    //   state: { id: { number } },
    // });
    // }
  };

  const navigateToBioflySensorTables = (deviceIDDetails) => {
    // if (json1.deviceType === "biofly") {
    // console.log("deviceType is biofly ");
    navigate("/BioflyDataInTables", {
      state: { id: { deviceIDDetails } },
    });
    // }
    // if (json1.deviceType === "shm") {
    // console.log("deviceType is shm ");
    // navigate("/ShmDeviceDetail", {
    //   state: { id: { number } },
    // });
    // }
  };

  const navigateToBioflySensorCharts = (deviceIDDetails) => {
    // if (json1.deviceType === "biofly") {
    // console.log("deviceType is biofly ");
    navigate("/BioflyDeviceCharts", {
      state: { id: { deviceIDDetails } },
    });
    // }
    // if (json1.deviceType === "shm") {
    // console.log("deviceType is shm ");
    // navigate("/ShmDeviceDetail", {
    //   state: { id: { number } },
    // });
    // }
  };

  // const navigateHome1 = (number) => {
  //   console.log("deviceType is biofly ");

  //   navigate("/BioflyDeviceDetail", {
  //     state: { id: { number } },
  //   });
  // };
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log('Only once execution here only...');

    // console.log("props: ", json1);
    // console.log("deviceIDDetails: ", json1.deviceIDDetails);
    // console.log("role: ", json1.role);
    // console.log("deviceType: ", json1.deviceType);

    // setUsers(json1.deviceIDDetails);

    // console.log("users1", props.users1);

    // const apis = "https://api.ezymote.com/api/deviceAssigned/" + user.email;

    const apis = `${baseUrl}/api/deviceAssigned/` + user.email;

    fetch(apis, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        const data1 = data[0];
        // console.log("data:", data1);
        // console.log("data.role:", data1.role);
        // console.log("data.deviceIDDetails:", data1.deviceIDDetails);
        // setItems(data);
        setUsers(data1.deviceIDDetails);
      });
  }, []);

  // const navigateToContacts = () => {
  //     // 👇️ navigate to /contacts
  //     navigate('/DeviceDetail');
  // };

  // var used =  users[0]
  // let user = '{"name": "Jason", "age": 25, "friends": [0,1,2]}';

  // user = JSON.parse(used);

  // console.log(user.deviceIDDetails);

  // console.log(user)

  // const navigateHome = (id) => {

  //     console.log("temp:")
  //     console.log(temp)

  //     // setData("tyu");
  //     //   👇️ navigate to /
  //     navigate('/DeviceDetail', {
  //         state: {
  //             deviceId: "123",
  //             id: temp
  //         }
  //     });
  // };

  // const incrementCount6 = () => {
  //   setCount(count + 1);
  //   setCount(count + 2);
  //   setCount(count + 3);
  // }

  // const navigateHome1 = (deviceID) => {

  //     temp = deviceID;

  //     console.log("temp:")
  //     console.log(temp)

  //     console.log("deviceID:")
  //     console.log(deviceID)

  //     setData(deviceID)

  //     console.log("data:")
  //     console.log(data)

  //     // navigateHome();
  // }

  // useEffect(() => {
  //     console.log("data11:")
  //     console.log(data)
  // }, [data])

  // const posts = React.useRef(null);

  // useEffect(() => {
  //     posts.current = 'values';
  //     console.log(posts.current)
  // }, [])

  const cardContainer = {
    height: "120px",
    width: "220px",
    background: "Cyan",
    borderRadius: "20px",
    // paddingLeft: "80px"
    // boxShadow: 10px 10px 38px 0px rgba(0, 0, 0, 0.75);
  };

  const enButton = {
    backgroundColor: "#4CAF50",
    border: "none",
    color: "white",
    padding: "15px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    margin: "4px 2px",
    cursor: "pointer",
  };

  return (
    // <div className="parent">
    //   <div class="row row-cols-1 row-cols-md-3 g-4">
    //     {users.map((deviceIDDetails) => (
    //       <div class="col">
    //         <Card style={{ width: "18rem" }}>
    //           <Card.Img variant="top" src="holder.js/100px180" />
    //           <Card.Body>
    //             <Card.Title>DeviceID: </Card.Title>
    //             <Card.Text>{deviceIDDetails}</Card.Text>
    //             <Button
    //               variant="primary"
    //               onClick={() => {
    //                 navigateToBioflySensorCharts(deviceIDDetails);
    //               }}
    //             >
    //               Show Charts
    //             </Button>

    //             <Button
    //               variant="primary"
    //               onClick={() => {
    //                 navigateToBioflySensorCharts(deviceIDDetails);
    //               }}
    //             >
    //               Show Charts
    //             </Button>

    //             <Button
    //               variant="primary"
    //               onClick={() => {
    //                 navigateToBioflySensorCharts(deviceIDDetails);
    //               }}
    //             >
    //               Show Charts
    //             </Button>
    //           </Card.Body>
    //         </Card>
    //       </div>
    //     ))}
    //   </div>
    // </div>

    <div className="parent">
      {users.map((deviceIDDetails) => (
        <div className="child">
          <div className="cardSensor-bigcontainer">
            <br />
            <hr />
            <b>DeviceID: {deviceIDDetails}</b>

            <div className="child44">
              <div className="B1">
                <Button
                  variant="success"
                  onClick={() => {
                    navigateToBioflySensorCharts(deviceIDDetails);
                  }} 
                >
                  Show Charts
                </Button>{" "}
              </div>

              <div className="B1">
                <Button
                  variant="success"
                  onClick={() => {
                    navigateToBioflySensorTables(deviceIDDetails);
                  }}
                >
                  Show Tables
                </Button>{" "}
              </div>

              <div className="B1">
                <Button
                  variant="info"
                  onClick={() => {
                    navigateToBioflySensorBox(deviceIDDetails);
                  }}
                >
                  Show Details
                </Button>{" "}
              </div>
            </div>

            <hr />
            <div></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BioflyUserDetails;

// import React from 'react'
// import { Bar } from 'react-chartjs-2'
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

import UserData from "../components/UserData.jsx";

import NoUserData from "../components/NoUserData.jsx";

// import { useProSidebar } from "react-pro-sidebar";

// import TempSensorCard from '../components/tempSensorCard'
// import { useMemo } from "react";
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
// import React from "react";
// import ReactDOM from "react-dom";
// import React from "react";
// import ReactDOM from "react-dom";
// import ReactDOM from '../react-dom';
// import './index.css';
// import "../components/styles.css";
// import socketIO from "socket.io-client";
// import { useEffect, useState }from 'react'
// import { useWorkoutsContext } from "../hooks/useWorkoutsContext"
import { useAuthContext } from "../hooks/useAuthContext";
import { useSensorDatasContext } from "../hooks/useSensorDatasContext";

// components
// import WorkoutDetails from '../components/WorkoutDetails'
// import WorkoutForm from '../components/WorkoutForm'
import SensorDataDetails from "../components/SensorDataDetails";

// import { io } from 'socket.io-client';
// import { setSeconds } from 'date-fns'
// import BarChart from '../components/BarChart'
// import myGif from '../images/react-render-animated-gif-100.gif';

import ReactEnvironmentChart from "react-environment-chart";
import { Intensity } from "react-environment-chart";
import Thermometer from "react-thermometer-ecotropy";
import { Electricity } from "react-environment-chart";
import { render } from "react-dom";

import Typography from "@mui/material/Typography";
import { GrFanOption, GrFan } from "react-icons/gr";
import { FaFan, FaHeart, FaRegHeart } from "react-icons/fa";
import ToggleSwitch from "../components/ToggleSwitch";
import Switch from "react-switch";
import BioflyBox from "../components/bioflySensorBox.js";

import {
  createSearchParams,
  Link,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

const deepMerge = require("deepmerge");

const baseUrl = process.env.REACT_APP_API_URL;

// import { IconContext } from "react-icons";
// let socket;
//  const initiateSocketConnection = () => {
// var socket = io(process.env.SERVER_ADDRESS);
// console.log(`Connecting socket...`);
// console.log("socket:");
// console.log(socket);
// }
// let circleFillColor = "white";
// let text = '{ "deviceID": "TLM1001" }';
// var sensorDataDetails;
// var glabels = [];
// var gdatas = [];
// var sensord3;

// var sensord1;
// var sensord2;
// var sensord3;
// var sensord4;
// var sensord5;
// var sensord6;
// var sensord7;
// var sensord8;
// var sensord9;
// var sensord10;
// var sensord11;
// var sensord12;

// let xsr = 0;

// const ReMount = props => {
//   let [key, setKey] = React.useState(0);

//   return (
//     <div>
//       <button className="button" onClick={() => setKey(Date.now())}>
//         reload
//       </button>
//       <div key={key}>{props.children}</div>
//     </div>
//   );
// };

// const Circle = ({ pct, color, size }) => {
//   const circle = {
//     width: `${size}px`,
//     height: `${size}px`
//   };

//   const circleFill = {
//     background: `linear-gradient(transparent ${100 - pct}%, ${color} ${100 -pct}%)`
// };
//   return (
//     <div className="circle" style={circle}>
//       <div className="circle-fill" style={circleFill} />
//       <div className="circle-overlay" />
//       <div className="circle-fill-text">{pct}%</div>
//     </div>
//   );
// };

const Home = () => {
  // let [count, setCount] = useState(0);
  // const handleClick = async () => {
  //   if (!user) {
  //     return
  //   }
  //   // const response = await fetch('/api/workouts/' + workout._id, {
  //   //   method: 'DELETE',
  //   //   headers: {
  //   //     'Authorization': `Bearer ${user.token}`
  //   //   }
  //   // })
  //   // const json = await response.json()

  //   // if (response.ok) {
  //   //   dispatch({type: 'DELETE_WORKOUT', payload: json})
  //   // }
  //   // socket.emit("login", "test");
  //   // disconnectSocket();
  //   // sendDataToServer();
  //   socket.emit("login", "test");
  //   console.log("delete pressed");
  // }

  // const { workouts, dispatch } = useWorkoutsContext()
  const { user } = useAuthContext();

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     // socket.on("login123", (g_tankLevel, g_tankVolume) => {
  //     // xsr = g_tankLevel;

  //     // console.log("g_tankLevel: ");
  //     // console.log(g_tankLevel);

  //     // console.log("g_tankVolume: ");
  //     // console.log(g_tankVolume);

  //     // io.emit("login123", "test123");
  //     // }

  //     // socket.on("login123", function (msg) {
  //     //   console.log("msg: ");
  //     //   console.log(msg);

  //     // }

  //     // socket.on("login123", function (msg) {
  //     //   console.log("msg: ");
  //     // console.log(msg);
  //     // }

  //     // );

  //     // if(xsr <=20)
  //     // {
  //     //   circleFillColor = "red";
  //     // }
  //     // else if((xsr > 20) && (xsr <70))
  //     // {
  //     //   circleFillColor = "orange";
  //     // }
  //     // else if(xsr >=70)
  //     // {
  //     //   circleFillColor = "green";
  //     // }

  //     // socket.emit("login", text);

  //     /************ */

  //     // const fetchSensorDatas1 = async () => {
  //     //   const response = await fetch('/api/sensorDatas/TLM1001', {
  //     //     headers: { 'Authorization': `Bearer ${user.token}` },
  //     //   })
  //     //   const json = await response.json()

  //     //   // console.log(json)

  //     //         // console.log("Initial fetch of sensor data")

  //     //   if (response.ok) {
  //     //     sensord1 = json;
  //     //   }
  //     // }

  //     if (user) {
  //       // fetchSensorDatass("sarojflame6@gmail.com")
  //       // fetchSensorData("TLM1003");
  //       // fetchSensorDatas1()
  //     }
  //   }, 6000000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // });

  // const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log('Only once execution here only...');

    // const response = await fetch(`${baseUrl}/api/deviceAssigned/`, {

    const apis = `${baseUrl}/api/deviceAssigned/` + user.email;

    fetch(apis, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        const data1 = data[0];
        // console.log("data:", data1);
        // console.log("data.role:", data1.role);
        // console.log("data.deviceType:", data1.deviceType);
        // setItems(data);

        if (data1.role === "admin") {
          // console.log("Navigate to admin panel");
          navigate("/Admin");
        } else if (data1.role === "client") {
          // console.log("Navigate to client panel");

          if (data1.deviceType === "biofly") {
            // console.log("deviceType is biofly ");
            navigate("/BioflyUserDetails");
          } else if (data1.deviceType === "shm") {
            // console.log("deviceType is shm ");
            navigate("/ShmUserDetails");
          } else if (data1.deviceType === "ezytrack") {
            // console.log("deviceType is shm ");
            navigate("/EzytrackUserDetails");
          } else if (data1.deviceType === "ezymoteHA3L1F") {
            // console.log("deviceType is ezymote ");
            navigate("/EzymoteUserDetails");
          }
        }
      });
  }, []);

  // render() {
  // const appContainer = {
  //   height: "280px",
  //   width: "270px",
  //   background: "Cyan",
  //   borderRadius: "20px",
  //   // paddingLeft: "80px"
  //   // box-shadow: 10px 10px 38px 0px rgba(0, 0, 0, 0.75);
  // };

  // const cardContainer = {
  //   height: "120px",
  //   width: "220px",
  //   background: "Cyan",
  //   borderRadius: "20px",
  //   // paddingLeft: "80px"
  //   // boxShadow: 10px 10px 38px 0px rgba(0, 0, 0, 0.75);
  // };

  // };

  const navigate = useNavigate();

  // Uninitialized state will cause Child to error out
  const [items, setItems] = useState();

  // Data does't start loading
  // until *after* Parent is mounted
  useEffect(() => {
    // const apis = "/api/deviceAssigned/" + useremail;
    // // console.log("apis:")
    // // console.log(apis)
    // const response = await fetch(apis, {
    //   headers: { Authorization: `Bearer ${user.token}` },
    // });
  }, []);

  return (
    <div>
      {/* <div className="parent"> */}
      {/* My Devices
      <div> */}
      {/* {items && <UserData items={items} />} */}
      {/* {deviceIDDetails ? ( 
          <UserData users={deviceIDDetails} users1={"saroj"} />         
        ) : (
          <NoUserData users={deviceIDDetails} />
        )} */}
      {/* </div> */}
      {/* <UserData users={deviceIDDetails} users1={deviceIDDetails123} /> */}
      {/* <div className="parent">
        <UserData users={deviceIDDetails} />
      </div> */}
      {/* <>
            {
              userss.map((number) =>
                

                <div id="div_top_hypers" key={number}>
                <ul id="ul_top_hypers">
  <li>
  <div className='child'>
        <div className="cardSensor-bigcontainer">
        {number}
        <Menu menuItemStyles>
   <MenuItem component={<Link to="/Graphs" />}> Charts</MenuItem> 
   </Menu>
        </div>
        </div>
 
        </li>
        </ul></div>
)
            }
        </> */}
      {/* <div className='child'>
        <div style={appContainer}>
          <Thermometer
            theme="light"
            value="18"
            max="100"
            steps="3"
            format="°C"
            size="medium"
            height="120"
          />
        </div>
      </div>   */}
      {/* <BioflyBox deviceID={deviceIDName}/> */}
      {/* <div className='child'>
        <div className="cardSensor-bigcontainer">
          <Typography
            align='center'
            variant="h6"
            fontWeight={1000}
            style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px', padding: '10px' }}
          >
            Device ID: 
          </Typography>
        </div>
        <Typography
          align='center'
          variant="subtitle1"
          fontWeight={1000}
          style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px', padding: '10px' }}
        >
          Lux
        </Typography>
      </div> */}
      {/* <div>
 
      <GrFanOption style={{color: 'red', fontSize: '50px'}}/>
      <FaFan style={{color: 'green', fontSize: '50px'}}/>
      <GiComputerFan style={{color: 'red', fontSize: '50px'}}/>
      <GrFan style={{color: 'green', fontSize: '50px'}}/>

    </div> */}
      {/* <Switch
    ballclass="circle"    //CSS class for circle ball in the switch, dont use the name of the class 'innercircle'
    outerdiv="outdiv"     // CSS class for the outer div where the circle will move
    change={change}       //change state from the useState
    setchange={setchange} // setchange state from the useState
    speed={"0.2s"}        // speed of the movement make sure to write 's' in the end
    ONCOLOR={"#002D6F"}   // ON color of the outer div
    OFFCOLOR={"grey"}     // OFF color of the outer div
    /> */}
      {/* <div className="container row p-0 my-5 mx-5">
        <div className="col-sm-4 ">
          <Switch
            ballclass="circle"
            outerdiv="outdiv"
            change={change}
            setchange={setchange}
            speed={"0.2s"}
            ONCOLOR={"#002D6F"}
            OFFCOLOR={"grey"}
          />
        </div>
        <div className="col-sm-7 p-0">
          <span> Remote</span>
        </div>
      </div> */}
      {/* <div class='child'>
        <div className="sensorValueBoxestest">
          <div>
            {sensord3 && sensord3.map((sensorData) => (
              <SensorDataDetails key={sensorData._id} sensorData={sensorData} />
            ))}
          </div>
        </div>
      </div> */}
    </div>

    //     <div style={({ flexDirection: "row" },{ flexWrap: "wrap" })}>

    //     </div>
  );
};

export default Home;
